/* eslint-disable @typescript-eslint/no-explicit-any */
const backendUrl = 'https://testharness-api-dev.azurewebsites.net';
//const backendUrl = 'https://localhost:7035';

export default class RestUtilities {
  static get(path: string, apiToken: string) {
    return RestUtilities.request('GET', path, apiToken);
  }

  static getLiteTests(container: string, apiToken: string) {
    return RestUtilities.request(
      'GET',
      '/bundle/GetTests',
      apiToken,
      `?containerName=${container}`
    );
  }

  static getTest(container: string, testId: string, apiToken: string) {
    return RestUtilities.request(
      'GET',
      '/bundle/Get',
      apiToken,
      `?testId=${container}.${testId}`
    );
  }

  static putTest(container: string, testModel: any, apiToken: string) {
    return RestUtilities.request(
      'PUT',
      '/bundle/Put',
      apiToken,
      `?containerName=${container}`,
      testModel
    );
  }

  static deleteTest(container: string, testModel: any, apiToken: string) {
    return RestUtilities.request(
      'DELETE',
      '/bundle/Delete',
      apiToken,
      `?containerName=${container}`,
      testModel
    );
  }

  static request = async (
    method: string,
    path: string,
    apiToken: string,
    query?: string,
    data?: any
  ) => {
    let ok = false;
    let body = data;
    let statusCode = 200;
    let statusText = '';
    const headers = new Headers();

    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `Bearer ${apiToken}`);
    body = JSON.stringify(data);

    return fetch(`${backendUrl}${path}${query ? query : ''}`, {
      method,
      headers,
      body,
    })
      .then((response) => {
        ok = response.ok;
        statusCode = response.status;
        statusText = response.statusText;
        const responseContentType = response.headers.get('content-type');
        if (
          responseContentType &&
          responseContentType.indexOf('application/json') !== -1
        ) {
          return response.json();
        }
        return response.text();
      })
      .then((responseContent) => {
        const response = {
          ok: ok,
          hasModelErrors: statusCode === 400,
          content: ok ? responseContent : null,
          errorContent: ok ? null : responseContent,
          status: statusCode,
          statusText: statusText,
        };
        return response;
      });
  };
}
