import React, { useEffect } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';

import RestUtilities from '../services/RestUtilities';
import {
  apiTokenState,
  containerState,
  containersState,
  loadingState,
} from '../state/RecoilState';

const TestSelectors: React.FC = () => {
  const setLoading = useSetRecoilState(loadingState);
  const [containers, setContainers] = useRecoilState(containersState);
  const [container, setContainer] = useRecoilState(containerState);
  const [apiToken, setApiToken] = useRecoilState(apiTokenState);

  useEffect(() => {
    const fetchContainers = async () => {
      setLoading(true);
      const res = await RestUtilities.get('/bundle/GetContainerIds', apiToken);
      if (res.ok) {
        setContainers(res.content);
        setContainer(res.content[1]);
      }
      setLoading(false);
    };
    if (containers.length === 0) {
      fetchContainers();
    }
  }, [apiToken, containers, setContainer, setContainers, setLoading]);

  const handleContainerChange = (event: SelectChangeEvent) => {
    setContainer(event.target.value as string);
  };

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
      <TextField
        id='outlined-basic'
        label='API Token'
        variant='outlined'
        size='small'
        value={apiToken}
        onChange={(e) => setApiToken(e.target.value)}
      />
      <FormControl sx={{ width: 200, ml: 2 }} size='small'>
        <InputLabel id='container-select-label'>POS Container</InputLabel>
        <Select
          labelId='container-select-label'
          id='container-select'
          value={container}
          label='POS Container'
          onChange={handleContainerChange}
          disabled={!containers.length}
        >
          {containers.map((c) => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TestSelectors;
